/**
Estandarizacion de tamaños de pantalla
 */

@mixin media-phone {
  @media (max-width: 575.98px) {
    @content
  }
}

@mixin media-tablet {
  @media (max-width: 1365px) {
    @content
  }
}

@mixin media-desktop {
  @media (min-width: 1366px) {
    @content
  }
}
