/**
Global theming
 */
@import "variables";

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}


/**
Estilos para la tabla y paginador de angular material
 */
.mat-mdc-paginator-container {
  flex-direction: row-reverse;
}

.mat-mdc-paginator-range-actions div {
  order: 1;
}

.mat-mdc-paginator-range-actions button:nth-child(2) {
  order: 0;
}

.mat-mdc-paginator-range-actions button:nth-child(3) {
  order: 2;
}


.mat-mdc-header-row {
  background-color: $clm-table-header-color !important;
}

.mat-mdc-row:nth-child(odd) {
  background-color: $clm-table-row-odd-color;
}


.mat-mdc-row:nth-child(even) {
  background-color: $clm-table-row-even-color;
}

.clm-table-white {
  .mat-mdc-header-row {
    background-color: white !important;
  }

  .mat-mdc-row:nth-child(odd) {
    background-color: white;
  }


  .mat-mdc-row:nth-child(even) {
    background-color: white;
  }

  .mdc-data-table__row:not(.mdc-data-table__row--selected):hover {
    background: white !important;
  }
}
