@import "breakpoints";

/**
Global CSS
 */

html, body {
  height: 100%;
}

body {
  margin: 0;
}

::placeholder {
  opacity: 1 !important;
}

.mat-mdc-select-placeholder {
  -webkit-text-fill-color: rgba(148, 163, 184, 1) !important;
}

.mat-mdc-select-invalid {
  .mat-mdc-select-placeholder {
    -webkit-text-fill-color: var(--mat-form-field-error-text-color) !important;
  }
}

.clm-mat-label {
  color: rgba(30,41,59, 0.7) !important;
  font-weight: 600;
  font-size: 14px;
  font-family: var(--mdc-filled-text-field-label-text-font);
  margin-bottom: 10px;
  line-height: normal;
}

/**
Estilos para el scroll bar
 */

::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  background-clip: padding-box;
  border-radius: 10px;
  background-color: lightgray;
}


.clm-dialog-content {
  width: 100vw;
  max-width: 500px;
  @include media-phone {
    max-width: 100%;
  }
}

.grid-cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(min(100%, 22rem), 1fr));
  row-gap: 24px;
  column-gap: 16px;
}

.fs-icon-sm {
  font-size: 1.21rem;
  height: 1.3rem!important;
  width: 1.3rem!important;
}
.fs-icon-sm-2 {
  font-size: 1.21rem;
  height: 1.5rem!important;
  width: 1.5rem!important;
}

.flex-1 {
  flex: 1;
}

.ck-editor__editable_inline {
  height: 100%;
  border: 1px solid var(--ck-color-base-border)!important;
  border-top: none!important;
}

// Button Sizes
.btn-h-xs {
  height: var(--btn-h-xs)!important;
 }
.btn-h-sm {
  height: var(--btn-h-sm)!important;
 }
.btn-h-md {
  height: var(--btn-h-md)!important;
 }
.btn-h-lg {
  height: var(--btn-h-lg)!important;
 }


//  General classes
.fw-500 {
  font-weight: 500;
}

.scroll-sm {
  max-height: 15rem;

  overflow: scroll;
}
.scroll-sm::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}
.scroll-sm::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  background-clip: padding-box;
  border-radius: 10px;
  background-color: lightgray;
}
.scroll-sm-plus {
  max-height: 20rem;

  overflow: scroll;
}
.scroll-sm-plus::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}
.scroll-sm-plus::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  background-clip: padding-box;
  border-radius: 10px;
  background-color: lightgray;
}

.scroll-md {
  max-height: 27rem;

  overflow: scroll;
}
.scroll-md::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}
.scroll-md::-webkit-scrollbar-thumb {
  border: 4px solid transparent;
  background-clip: padding-box;
  border-radius: 10px;
  background-color: lightgray;
}

.text-c-primary {
  color: var(--color-primary);
}

.svg-sm {
  width: 1.5rem;
}

.ck-content-custom {
  table {
    border-collapse: collapse;
    width: 100%;
  }

  /* Estilo de borde de celda */
  td, th {
    border: 1px solid #dddddd;
    padding: 8px;
  }
}

// BTNs
.custom-btn--btn-sm.mat-mdc-unelevated-button,
.custom-btn--btn-sm.mat-mdc-outlined-button {
  min-height: 30px!important;
  max-height: 30px!important;
  height: 30px;
  font-size: 13px!important;
}

.status-btn-Rejected {
  color: #DC3545;
}
.status-btn-ReadyForCheck {
  color: #4f46e5;
}
.status-btn-Checked {
  color: #198754;
}

.clm-field-auto-height {
  field-sizing: content;
}
