/**
Variables CSS
 */

$clm-gradient-color: linear-gradient(to right, rgb(236,115,37), rgb(186,66,142));
$clm-global-border: 1px solid rgb(199, 199, 199);
$clm-kamila-info-color: white;
$clm-login-page-backgroud: rgba(222, 222, 222, 0.721);
$clm-login-backgroud: white;
$clm-withe-backgroud: white;
$clm-login-container-heigth: 550px;
$clm-login-container-width: 950px;
$clm-login-container-resposive-heigth: 550px;
$clm-login-container-resposive-width: 100%;
$clm-login-logo-size: 150px;
$clm-table-header-color: #EEEEEE;
$clm-table-row-odd-color: #F5F5F5;
$clm-table-row-even-color: #FAFAFA;
$clm-precontract-attachment-card-list-max-height: 300px;
$clm-bc-documents-modal-preview-heigth: 160px;

:root {
  --sidebar-width: 270px;
  --sidebar-height: 100vh;
  --navbar-height: 50px;
  --small-sidebar-width: 96px;

  /*Screen sizes*/
  --screen-sm: 576px;
  --screen-md: 768px;
  --screen-lg: 992px;
  --screen-xl: 1200px;
  --screen-xxl: 1400px;

  /* Avatar sizes */
  --avatar-xs: 2rem;
  --avatar-sm: 2.5rem;
  --avatar-md: 3.5rem;
  --avatar-lg: 5rem;

  /*Modal Sizes*/
  --modal-sm: 300px;
  --modal-md: 500px;
  --modal-lg: 800px;
  --modal-xl: 1140px;

  /* Button Sizes */
  --btn-h-xs: 1.5rem;
  --btn-h-sm: 2rem;
  --btn-h-md: 2.5rem;
  --btn-h-lg: 3rem;

  /*displays*/

  /*Variables generales*/
  --box-shadow-1: 0 1px 3px 0 #0000001a, 0 1px 2px 0 #0000000f;
  --box-shadow-2: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --gap-1: 0.5rem;
  --gap-2: 1rem;
  --gap-3: 1.5rem;
  --margin-bottom-1: 0.5rem;
  --margin-bottom-2: 1rem;
  --line-height-1: 0.5;
  --line-height-2: 1;
  --line-height-3: 1.5;
  --line-height-4: 2rem;
  --line-height-5: 2.5rem;
  --padding-1: 0.5rem;
  --padding-2: 0.5rem 1rem;
  --padding-3: 1rem;
  --padding-4: 1rem 1.5rem;
  --padding-5: 1.5rem;
  --padding-6: 1.5rem 1rem;
  --padding-7: 2rem;
  --padding-8: 2rem 2.5rem;
  --margin-1: 0.5rem;
  --margin-5: 1.5rem;
  --border-1: 1px;
  --border-2: 2px;
  --border-3: 3px;
  --border-4: 4px;
  --border-radius-1: 4px;
  --border-radius-2: 1rem;
  --border-radius-3: 1.5rem;
  --border-radius-4: 2.5rem;
  --font-weight-bold: 700;
  --font-weight-semibold: 600;
  --font-weight-normal: 400;
  --font-weight-light: 300;
  --font-size-1: 0.8rem;
  --font-size-2: 1rem;
  --font-size-3: 1.25rem;
  --font-size-4: 1.5rem;
  --font-size-5: 2rem;

  /*Colors*/
  --color-primary: #4454C5;
  --color-primary-rgb: 68, 84, 197;
  --color-secondary: #F8305A;
  --color-secondary-rgb: 248, 48, 90;
  --color-info: #0dcaf0;
  --color-info-rgb: 13, 202, 240;
  --color-success: #198754;
  --color-success-rgb: 25, 135, 84;
  --color-warning: #ffc107;
  --color-warning-rgb: 255, 193, 7;
  --color-danger: #dc3545;
  --color-danger-rgb: 220, 53, 69;
  --color-ghost: #f0f8ff;
  --color-ghost-rgb: 240, 248, 255;
  --color-light: #fff;
  --color-light-rgb: 255, 255, 255;
  --color-light-2: #f9f9f9;
  --color-light-2-rgb: 240, 240, 240;
  --color-dark: #000;
  --color-dark-rgb: 0, 0, 0;
  --color-gray-400: #ced4da;
  --color-gray-400-rgb: 206, 212, 218;
  --color-gray-600: #475569;
  --color-gray-600-rgb: 71, 85, 105;
  --color-gray-700: #334155;
  --color-gray-700-rgb: 51, 65, 85;
  --color-gray-900: #212529;
  --color-gray-900-rgb: 33, 37, 41;
  --opacity-color-10: 0.1;
  --opacity-color-20: 0.2;
  --opacity-color-30: 0.3;
  --opacity-color-40: 0.4;
  --opacity-color-50: 0.5;
  --opacity-color-60: 0.6;
  --opacity-color-70: 0.7;
  --opacity-color-80: 0.8;
  --opacity-color-90: 0.9;
  --opacity-color-100: 1;

  /*1.Accordion*/
  --margin-bottom-accordion: 0.2rem;
  --border-radius-accordion: 0.5rem;
  /*2.Buttons*/
  /*AppButton*/
  --padding-button: 1.2rem 1.2rem;
  --border-color: transparent;
  --width-btn-icon: 40px;
  --height-btn-icon: 40px;
  --btn-hover-opacity: 1;
  --border-color-ghost: rgba(203, 213, 225);
  /*AppButtonBack*/

  /*AppButtonChat*/
  /*AppButtonContainerNew*/
  /*AppButtonDelete*/
  --width-buttons: 40px;
  --height-buttons: 40px;
  /*AppButtonEdit*/
  /*3.Cards*/
  /*4.Datatable*/
  /*AppDatatable*/
  --padding-table: 2rem;
  --padding-table-filter: 2rem;
  --border-radius-form-control: 6px;
  /*AppDatatableColumn*/
  /*AppDatatableFilters*/
  --width-responsive-dropdown-menu-filter: auto;
  --min-width-responsive-dropdown-menu-filter: 350px;
  --left-responsive-dropdown-menu-filter: 50%;
  --position-responsive-dropdown-menu-filter: fixed;

  /*6.AppBreadCrumbs*/
  /*--bs-breadcrumb-divider: url()*/
  /*7. Login*/
  --border-radius-login: 0.7rem;
  /*8.Forms*/
  /*AppSelect*/
  --border-radius-select: 0.7rem;
  --padding-select: 0.5rem 0;
}
